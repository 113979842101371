import React, { useRef, useEffect } from "react";
import ArrowLeft from "../../assets/Icons/arrowLeft.png";
import ArrowRight from "../../assets/Icons/arrowRight.png";
import Logo3 from "../../assets/Logos.nobackground/3.png";
import Logo2 from "../../assets/Logos.nobackground/2.png";
import Logo1 from "../../assets/Logos.nobackground/bex.png";
import Logo4 from "../../assets/Logos.nobackground/4.png";
import Logo5 from "../../assets/Logos.nobackground/5.png";
import Logo6 from "../../assets/Logos.nobackground/6.png";
import Logo7 from "../../assets/Logos.nobackground/7.png";
import Logo8 from "../../assets/Logos.nobackground/8.png";
import Logo9 from "../../assets/Logos.nobackground/9.png";
import Logo10 from "../../assets/Logos.nobackground/10.png";
import Logo11 from "../../assets/Logos.nobackground/11.png";
import Logo12 from "../../assets/Logos.nobackground/12.png";
import Logo13 from "../../assets/Logos.nobackground/13.png";

const Banner = () => {
  const bannerRef = useRef(null);
  const scrollIntervalRef = useRef(null);
  const isHoveringRef = useRef(false);

  const startAutoScroll = () => {
    // Check if the user is currently hovering over the banner
    if (!isHoveringRef.current) {
      if (scrollIntervalRef.current) {
        clearInterval(scrollIntervalRef.current);
      }
      scrollIntervalRef.current = setInterval(() => {
        bannerRef.current.scrollLeft += 2;
      }, 50);
    }
  };

  const stopAutoScroll = () => {
    clearInterval(scrollIntervalRef.current);
  };

  const handleScroll = (direction) => {
    stopAutoScroll(); // Stop auto-scrolling when manually scrolling
    isHoveringRef.current = true; // Indicate that the user is interacting
    const { current } = bannerRef;

    if (direction === "left") {
      current.scrollBy({ left: -500, behavior: "smooth" });
    } else {
      current.scrollBy({ left: 500, behavior: "smooth" });
    }
  };

  useEffect(() => {
    startAutoScroll(); // Start auto-scrolling when the component mounts

    return () => {
      stopAutoScroll(); // Clear the interval when the component unmounts
    };
  }, []);

  return (
    <div className="banner-container">
      <p>Neki od naših dugogodišnjih klijenata:</p>

      <div className="banner">
        <img
          src={ArrowLeft}
          alt="Scroll Left"
          className="scroll-arrow left"
          onClick={() => handleScroll("left")}
        />
        <div
          className="banner-content"
          ref={bannerRef}
          onMouseEnter={() => {
            isHoveringRef.current = true;
            stopAutoScroll();
          }}
          onMouseLeave={() => {
            isHoveringRef.current = false;
            startAutoScroll();
          }}
        >
          <img src={Logo1} alt="JKP Pančevo" />
          <img src={Logo2} alt="AKS Kurirska Služba" />
          <img src={Logo3} alt="Autoprevoz Banjaluka" />
          <img src={Logo4} alt="C&LC Group" />
          <img src={Logo5} alt="Slavonija Bus" />
          <img src={Logo6} alt="Bijeljina put" />
          <img src={Logo7} alt="JP Subotica Trans" />
          <img src={Logo8} alt="GSP Beograd" />
          <img src={Logo9} alt="Knez Petrol" />
          <img src={Logo10} alt="Boksit Milići" />
          <img src={Logo11} alt="Trans Turist Tuzla" />
          <img src={Logo12} alt="Lasta" />
          <img src={Logo13} alt="Linde - Material Handling" />
        </div>
        <img
          src={ArrowRight}
          alt="Scroll Right"
          className="scroll-arrow right"
          onClick={() => handleScroll("right")}
        />
      </div>
    </div>
  );
};

export default Banner;
