import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Banner from "./banner";

const AboutSecond = () => {
  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);

  return (
    <div className="about-second__container">
      <div className="about-title">
        <h1>O nama</h1>
      </div>
      <div className="text-container__first" data-aos="fade-right">
        <p>
          <span className="bold">Bosch servis „Ilija Burin“</span> je porodična
          firma sa preko 40 godina iskustva, početke beleži u maloj
          automehaničarskoj radnji od 15 m2, koja se zahvaljujući upornosti i
          viziji osnivača i direktora g. Ilije Miloševića, tokom godina
          kontinuirano razvijala i postala jedan od vodećih servisa, kako u
          Srbiji, tako i na prostorima stare Jugoslavije.
        </p>
        <p>
          Firma se trenutno prostire na 1600 m2, i predstavlja jedan od
          najopremljenijih servisa na ovim prostorima. Svoju poziciju firma
          gradi na kontinuiranom investiranju u savremenu opremu i ljudske
          resurse, uz uvažavanje društveno odgovornih komponenti u poslovanju.
        </p>
        <p>
        
        </p>
      </div>
      <div className="text-container__second" data-aos="fade-right">
        <h2>Saradnici</h2>
        <p>
          Održavamo i negujemo dobre saradničke odnose sa poslovnim partnerima i
          širom društvenom zajednicom. Firma posluje sa preko 200 firmi u zemlji
          i regionu, a svoje usluge pružamo i klijentima širom sveta (Austrija,
          Nemačka, Francuska, Gvineja).
        </p>
      </div>
      <Banner />
    </div>
  );
};

export default AboutSecond;
