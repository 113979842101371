import React, { useEffect } from "react";
import Navbar from "../molecules/navbar";
import { useLocation } from "react-router-dom";
import AboutSection from "../organisms/aboutSection";
import ServiceSection from "../organisms/serviceSection";
import ContactSection from "../organisms/contactSection";
import ContactBanner from "../atoms/contactBanner";
import HomeSection from "../organisms/homeSection";
import Gallery from "../organisms/gallery";

const useScrollToSection = () => {
  const location = useLocation();

  useEffect(() => {
    const hash = location.hash;

    const scrollToElement = () => {
      if (hash) {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);

        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        } else {
          setTimeout(scrollToElement, 100);
        }
      }
    };

    scrollToElement();
  }, [location]);
};

export default function MainPage() {
  useScrollToSection();
  return (
    <div className="main-page">
      <div className="navbar">
        <Navbar />
      </div>

      <section id="home">
        <HomeSection />
      </section>

      <section id="about">
        <AboutSection />
      </section>

      <section id="services">
        <ServiceSection />
      </section>

      <section id="gallery">
        <Gallery />
      </section>

      <section id="contact">
        <ContactSection />
      </section>

      <div id="banner">
        <ContactBanner />
      </div>
    </div>
  );
}
