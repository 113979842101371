import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "aos/dist/aos.css";
import "../../styles/App.scss";
import MainPage from "../templates/MainPage";
import Gallery from "../organisms/gallery";
import ServicePage from "../templates/ServicePage";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<MainPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
