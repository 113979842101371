import React, { useState } from "react";

const ContactSection = () => {
  const [activeTab, setActiveTab] = useState("TELEFON");

  return (
    <div className="contact-section">
      <div className="wrapper">
        <div className="contact-content">
          <h1>Kontakt</h1>
          <p>Potrebna vam je dijagnostika, popravka, ispitivanje ili remont?</p>
          <p>Pozovite nas ili posetite na dobro poznatoj adresi!</p>

          <div className="contact-tabs">
            <div
              className={`contact-tab ${activeTab === "TELEFON" ? "active" : ""}`}
              onClick={() => setActiveTab("TELEFON")}
            >
              TELEFON
            </div>
            <div
              className={`contact-tab ${activeTab === "ADRESA" ? "active" : ""}`}
              onClick={() => setActiveTab("ADRESA")}
            >
              ADRESA
            </div>
            <div
              className={`contact-tab ${activeTab === "RADNO VREME" ? "active" : ""
                }`}
              onClick={() => setActiveTab("RADNO VREME")}
            >
              RADNO VREME
            </div>
          </div>

          <div className="tab-content">
            {activeTab === "TELEFON" && (
              <div className="tab-panel">
                <p>
                  Telefon: <a href="tel:0157741300">015/7741300</a>
                </p>
                <p>
                  Telefon/Fax: <a href="tel:0157751712">015/7751712</a>
                </p>
                <p>
                  Email:{" "}
                  <a href="mailto:bdsburin@gmail.com">bdsburin@gmail.com</a>
                </p>
                <p>
                  Vlasnik i direktor servisa:{" "}
                  <a href="tel:063372648">Dragoljub Milošević - 063 372 648</a>
                </p>
                <p>
                  Menadžer servisa:{" "}
                  <a href="tel:0648738755">Dejan Gačević - 064 8738755</a>
                </p>
              </div>
            )}
            {activeTab === "ADRESA" && (
              <div className="tab-panel">
                <p>
                  Adresa: <a href="/">Pocerska 42, Šabac, Mačvanski okrug</a>
                </p>
              </div>
            )}
            {activeTab === "RADNO VREME" && (
              <div className="tab-panel">
                <p>
                  Radno vreme: <a href="/">Ponedeljak - Subota 08:00h - 16:00h</a>
                </p>
              </div>
            )}
          </div>
        </div>

      </div>
      <div className="contact-image"></div>
    </div>
  );
};

export default ContactSection;
