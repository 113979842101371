import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";
import gallery1 from "../../assets/Images/1.JPG";
import gallery2 from "../../assets/Images/2.JPG";
import gallery4 from "../../assets/Images/4.JPG";
import gallery5 from "../../assets/Images/5.JPG";
import gallery6 from "../../assets/Images/6.JPG";
import gallery7 from "../../assets/Images/7.jpg";
import gallery11 from "../../assets/Images/11.JPG";
import gallery14 from "../../assets/Images/14.JPG";
import gallery15 from "../../assets/Images/15.JPG";
import gallery18 from "../../assets/Images/18.JPG";
import gallery20 from "../../assets/Images/20.JPG";
import "../../styles/screens/gallery.scss";

const Gallery = () => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const observer = useRef();

  const imageList = useMemo(
    () => [
      gallery1,
      gallery2,
      gallery4,
      gallery5,
      gallery6,
      gallery7,
      gallery11,
      gallery14,
      gallery15,
      gallery18,
      
    
    ],
    []
  );


  const showPrevious = useCallback(() => {
    setSelectedImageIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : imageList.length - 1
    );
  }, [imageList]);

  const showNext = useCallback(() => {
    setSelectedImageIndex((prevIndex) =>
      prevIndex < imageList.length - 1 ? prevIndex + 1 : 0
    );
  }, [imageList]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 37) {
        showPrevious();
      } else if (event.keyCode === 39) {
        showNext();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [selectedImageIndex, showNext, showPrevious]);

  const openImage = (index) => {
    setSelectedImageIndex(index);
  };

  const closeImage = () => {
    setSelectedImageIndex(null);
  };

  useEffect(() => {
    observer.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const lazyImage = entry.target;
          lazyImage.src = lazyImage.dataset.src;
          observer.current.unobserve(lazyImage);
        }
      });
    });
  }, []);

  useEffect(() => {
    const images = document.querySelectorAll("img[data-src]");
    images.forEach((img) => observer.current.observe(img));
  }, [imageList]);


  return (
    <div className="gallery-container">
      <div className="gallery-title">
        <h2>Galerija</h2>
      </div>
      <div className="gallery">
        {imageList.map((image, index) => (
          <div
            className="gallery__item"
            key={index}
            onClick={() => openImage(index)}
          >
            <img
              src={image}
              alt={`Gallery item ${index + 1}`}
              className="gallery__image"
            />
          </div>
        ))}
        {selectedImageIndex !== null && (
          <div className="gallery__modal" onClick={closeImage}>
            <div
              className="gallery__modal-content"
              onClick={(e) => e.stopPropagation()}
            >
              <img
                src={imageList[selectedImageIndex]}
                alt={`Gallery item ${selectedImageIndex + 1}`}
                className="gallery__modal-image"
              />
              <button className="gallery__modal-prev" onClick={showPrevious}>
                &#10094;
              </button>
              <button className="gallery__modal-next" onClick={showNext}>
                &#10095;
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Gallery;
