import AboutFirst from "../molecules/aboutFirst";
import AboutSecond from "../molecules/aboutSecond";
import AboutThird from "../molecules/aboutThird";

const aboutSection = () => {
  return (
    <div className="about">
      <AboutSecond />
      <AboutFirst />
      <AboutThird />
    </div>
  );
};

export default aboutSection;
