import React, { useState } from "react";
import MobileNav from "../atoms/mobileNav";
import ArrowIcon from "../../assets/Icons/arrowDown.png";
import Logo from "../atoms/logo";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleServiceClick = () => {
    setShowDropdown(false);
  };

  const handleNavLinkClick = (event) => {
    const href = event.currentTarget.getAttribute("href");
    if (href.startsWith("#")) {
      event.preventDefault();
      window.location.hash = href;
    }
  };

  return (
    <div id="nav-container">
      <MobileNav />
      <Logo />
      <div className="sidebar">
        <a href="/#home" className="menu-item" onClick={handleNavLinkClick}>
          Početna
        </a>
        <a href="/#about" className="menu-item" onClick={handleNavLinkClick}>
          O nama
        </a>
        <a href="/#services" className="menu-item" onClick={handleNavLinkClick}>
          Usluge
        </a>
        <a href="/#gallery" className="menu-item" onClick={handleNavLinkClick}>
          Galerija
        </a>
        <a href="/#contact" className="menu-item" onClick={handleNavLinkClick}>
          Kontakt
        </a>
      </div>
    </div>
  );
};

export default Navbar;
