import HomeCard from "../molecules/homeCard";
import MapCard from "../molecules/mapCard";

const HomeSection = () => {
    return (
        <div className="home-section">
            <HomeCard />

            <MapCard />
        </div>
    );
};

export default HomeSection;
