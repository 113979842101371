import data from "../../assets/main.json";
import FirstIcon from "../../assets/Icons/edit.png";
import SecondIcon from "../../assets/Icons/loupe.png";
import ThirdIcon from "../../assets/Icons/computer.png";
import FourthIcon from "../../assets/Icons/maintenance.png";
import FifthIcon from "../../assets/Icons/screwdriver.png";
import SixthIcon from "../../assets/Icons/technical-assistance.png";
import SeventhIcon from "../../assets/Icons/technical-support.png";
import EighthIcon from "../../assets/Icons/toolbox.png";
import NinthIcon from "../../assets/Icons/tools.png";

const icons = [
  FirstIcon,
  SecondIcon,
  ThirdIcon,
  FourthIcon,
  FifthIcon,
  SixthIcon,
  SeventhIcon,
  EighthIcon,
  NinthIcon,
];

const Card = ({ title, iconSrc, items }) => (
  <div className="card">
    <span className="icon">
      <img src={icons[iconSrc]} alt={title} />
    </span>
    <h4>{title}</h4>
    <p>{items}</p>
    <div className="shine"></div>
    <div className="background">
      <div className="tiles">
        <div className="tile tile-1"></div>
        <div className="tile tile-2"></div>
        <div className="tile tile-3"></div>
        <div className="tile tile-4"></div>
        <div className="tile tile-5"></div>
        <div className="tile tile-6"></div>
        <div className="tile tile-7"></div>
        <div className="tile tile-8"></div>
        <div className="tile tile-9"></div>
        <div className="tile tile-10"></div>
      </div>
      <div className="line line-1"></div>
      <div className="line line-2"></div>
      <div className="line line-3"></div>
    </div>
  </div>
);

const Cards = () => (
  <div className="cards-container">
    {data.services.map((service, index) => (
      <Card
        key={index}
        title={service.title}
        iconSrc={index}
        items={service.items}
      />
    ))}
  </div>
);

export default Cards;
// const images = {
//   "DSC_3853.JPG": ImageFirst,
//   "DSC_3841.JPG": ImageSecond,
//   "DSC_3824.JPG": ImageThird,
// };

// const Card = ({ title, imageSrc, items, buttonText }) => (
//   <div className="card">
//     <img src={images[imageSrc]} alt={title} />
//     <div className="card-text">
//       <h2>{title}</h2>
//       <ul>
//         {items.map((item, index) => (
//           <li key={index}>{item}</li>
//         ))}
//       </ul>
//     </div>
//   </div>
// );

// const NewCard = ({ title, imageSrc, items, buttonText }) => (
//   <div className="card-cont">
//     <div className="card new-card">
//       {/* <img src={images[imageSrc]} alt={title} /> */}
//       <div className="card-text">
//         <h2 className="no-border">{title}</h2>
//         <ul>
//           {items.map((item, index) => (
//             <li key={index}>{item}</li>
//           ))}
//         </ul>
//       </div>
//       {/* <button>{buttonText}</button> */}
//     </div>
//   </div>
// );

// const Cards = () => (
//   <div className="cards-container">
//     {data.services.map((service, index) => {
//       // Using a ternary operator to decide whether to render Card or NewCard
//       // based on the index of the current item in the map
//       return <NewCard
//         key={index}
//         title={service.title}
//         imageSrc={service.image}
//         items={service.items}
//         buttonText={service.buttonText}
// />
//  index < 3 ? (
//   <Card
//     key={index}
//     title={service.title}
//     imageSrc={service.image}
//     items={service.items}
//     buttonText={service.buttonText}
//   />
// ) : (

// );
//     })}
//   </div>
// );
