import CallButton from "../atoms/callButton";

const HomeCard = () => {
  return (
    <div className="home-card">
      <div className="home_card-container">
        <div className="card-header">
          <h1>Bosch Servis</h1>
          <h2>"ILIJA BURIN"</h2>
        </div>
        <p>
          Visok nivo kvaliteta u pružanju usluga i brzine u poslovanju. Cilj nam je izgradnja dugoročnih, obostrano zadovoljavajućih odnosa sa
          klijentima.
        </p>
        <CallButton />
      </div>
    </div>
  );
};

export default HomeCard;
