import "../../styles/screens/serviceSection.scss";
import Cards from "../molecules/cards";

const ServiceSection = () => {
  return (
    <div className="service-container">
      <div className="service-title">
        <h1>Usluge</h1>
      </div>
      <Cards />
    </div>
  );
};

export default ServiceSection;
