import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import CheckedIcon from "../../assets/Icons/checkedIcon.png";
import image2 from "../../assets/diplome/2.JPG";
import image3 from "../../assets/diplome/3.JPG";
import image4 from "../../assets/diplome/4.JPG";
import image5 from "../../assets/diplome/5.JPG";
import image6 from "../../assets/diplome/6.JPG";
import image7 from "../../assets/diplome/7.JPG";


const AboutThird = () => {

  const imgList = [
    
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
  ];
  const [currentImage, setCurrentImage] = useState(imgList[0]);
  const [fadeClass, setFadeClass] = useState("fade-in");
  let imgIndex = 0;

  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
    const interval = setInterval(() => {
      setFadeClass("fade-out");
      setTimeout(() => {
        imgIndex = (imgIndex + 1) % imgList.length;
        setCurrentImage(imgList[imgIndex]);
        setFadeClass("fade-in");
      }, 1000); // matches the duration of the fade-out transition
    }, 5000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="about-third__container">
      <div className="subtitle">
        <h2>Šta nas izdvaja od konkurencije?</h2>
      </div>
      <div className="container">
        <div className="links">
          <div className="row" data-aos="fade-right">
            <img src={CheckedIcon} alt="Savremena originalna oprema" />
            <p>Savremena originalna oprema</p>
          </div>
          <div className="row" data-aos="fade-right">
            <img src={CheckedIcon} alt="Predusretljivost " />
            <p>Predusretljivost</p>
          </div>
          <div className="row" data-aos="fade-right">
            <img src={CheckedIcon} alt="Kompetentnost " />
            <p>Kompetentnost</p>
          </div>
          <div className="row" data-aos="fade-right">
            <img
              src={CheckedIcon}
              alt="Garancija za originalnost ugrađenih delova"
            />
            <p>Garancija za originalnost ugrađenih delova</p>
          </div>
          <div className="row" data-aos="fade-right">
            <img src={CheckedIcon} alt="Kredibilitet" />
            <p>Kredibilitet </p>
          </div>
          <div className="row" data-aos="fade-right">
            <img src={CheckedIcon} alt="Sigurnost u poslovanju" />
            <p>Sigurnost u poslovanju</p>
          </div>
          <div className="row" data-aos="fade-right">
            <img src={CheckedIcon} alt="Transparentnost u radu" />
            <p>Transparentnost u radu</p>
          </div>
          <div className="row" data-aos="fade-right">
            <img src={CheckedIcon} alt="Praćenje savremenih trendova" />
            <p>Praćenje savremenih trendova</p>
          </div>
          <div className="row" data-aos="fade-right">
            <img src={CheckedIcon} alt="Odgovornost" />
            <p>
              Odgovornost (za sve godine poslovanja firma nikada nije bila u
              blokadi)
            </p>
          </div>
        </div>
        <div className="image-container">
          <img src={currentImage} className={fadeClass} alt="Rotating Images" />
        </div>

      </div>
    </div>
  );
};

export default AboutThird;
