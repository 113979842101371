export default function MapCard() {
  return (
    <div className="map-card">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11335.459969547694!2d19.677597!3d44.7427711!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475bcb05efee9fe5%3A0x8aea93fae0ae1275!2sIlija%20Burin!5e0!3m2!1sen!2srs!4v1708895238089!5m2!1sen!2srs"
        width="400"
        title="bosch servis burin"
        height="400"
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
}
