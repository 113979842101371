import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

const AboutFirst = () => {
  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);

  return (
    <div className="about-first__container">
      <div className="image-container">
        {/* <img src={aboutImg} className="img1" alt="Najnovije Tehnologije"></img> */}
      </div>
      <div className="text-container">
        <h1>Najnovije tehnologije</h1>
        <p data-aos="fade-left">
          Sa opremom koju posedujemo, Bosch servis “Ilija Burin” je trenutno
          jedan od lidera u oblasti dizel ubrizgavanja. Opremljeni smo za
          dijagnostiku, popravku i ispitivanje celokupnog putničkog, teretnog
          (kamionski i autobuski) programa, kao i građevinskih i poljoprivrednih
          mašina. Jedini u Evropi posedujemo originalnu BOSCH opremu za
          ispitivanje AD blue sistema (DNOX 1, DNOX 2). Trenutno, jedini u
          Srbiji posedujemo HARTRIDGE TOLEDO za ispitivanje i rad katerpilarovih
          (HEUI, C7, C9, C13, C15) injektora.
        </p>
      </div>
    </div>
  );
};

export default AboutFirst;
